<template>
  <div class="confirm-account login-component">
    <div class="image-container"></div>
    <transition appear name="fadeRight" mode="out-in">
      <form @submit.prevent="validateForm" id="forgot-password-form-email" class="login-form black-background">
        <div class="container">
          <h2>{{lang.forgotPasswortEmail.headline1}}</h2>
          <p class="p-unstyled">{{lang.forgotPasswortEmail.message}} <i> {{lang.forgotPasswortEmail.emailSender}}</i></p>
          <md-field class="md-field-icon" :class="getValidationClass('email')">
            <i class="fas fa-envelope"></i>
            <label>{{lang.spielerForm.labelEMail}}</label>
            <md-input @blur="$v.email.$touch()" :name="lang.spielerForm.labelEMail" id="email" v-model.trim="email" autocomplete="email" type="email"></md-input>
            <md-icon v-if="isFieldInvalid('email')" class="color-invalid">close</md-icon>
            <span class="md-error" v-if="!$v.email.required">{{lang.spielerForm.emailRequired}}</span>
            <span class="md-error" v-else-if="!$v.email.email">{{lang.spielerForm.emailRequired}}</span>
          </md-field>
          <vue-hcaptcha 
              sitekey="74d4ee3c-859f-40e6-8e1e-b80c9c91fb6a" 
              :language="lang.name"
              @verify="onVerify"
              @expired="onExpire"
              @error="onError"
            ></vue-hcaptcha>
          <div class="button-list">
            <md-button type="submit" class="md-primary md-round md-raised md-center">
              {{lang.forgotPasswortEmail.buttonText}}
            </md-button>
            <a @click="$router.go(-1)">
              <md-button class="md-primary md-round md-raised md-center md-border">
                {{lang.forgotPasswortEmail.buttonTextBack}}
              </md-button>
            </a>
          </div>
        </div>
      </form>
    </transition>
    <SubmitModal/>
    <InformationModal/>
    <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
  </div>
</template>

<script>
import store from '@/store/index'
import SubmitModal from '../Utils/SubmitModal'
import InformationModal from '../Utils/InformationModal'
import router from '@/router/index'
import {required, email} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: 'ForgotPasswordEmail',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    InformationModal,
    VueHcaptcha
  },
  data() {
    return {
      email: "",
      captcha: {
        verified: false,
        expired: false,
        token: null,
        eKey: null,
        error: null,
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
  },
  created() {
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    loadingStatus() {
      return store.state.spieler.loadingStatus;
    },
  },
  methods: {
     onVerify(token, ekey) {
      this.captcha.verified = true;
      this.captcha.token = token;
      this.captcha.eKey = ekey;
    },
    onExpire() {
      this.captcha.verified = false;
      this.captcha.token = null;
      this.captcha.eKey = null;
      this.captcha.expired = true;
      this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.captcha.expired);
    },
    onError(err) {
      this.captcha.token = null;
      this.captcha.eKey = null;
      this.captcha.error = err;
      this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.captcha.error);
    },
    validateForm() {
      this.$v.$touch();

      if (!this.captcha.verified) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.captcha.required);
      }

      if (!this.$v.$invalid && this.captcha.verified) {
        this.sendForgotPasswordEmail();
      }
    },
    async sendForgotPasswordEmail() {
      if (this.isOffline) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
      } else {
        await store.dispatch('forgotPasswordEmail', {token: this.captcha.token, email: this.email})
          .then(result => {
            if (result.data != null) {
              this.showDialogActive(true, this.lang.forgotPasswortEmail.successHeadline, "");
              setTimeout(() => { 
                store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false);
                router.push({name: "Login"});
              }, store.state.submitModalSuccessSeconds);
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage);
            }
          })
          .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
          });
      }
    },
    getValidationClass(fieldName) {
      return {
        'md-invalid': this.isFieldInvalid(fieldName)
      }
    },
    isFieldInvalid(fieldName) {
      const field = this.$v[fieldName]
      if (field) {
        return field.$invalid && field.$dirty;
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';

.image-container {
  background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-5.jpg");
  background-position: 0% -3%;
}

h2 {
  margin-bottom: 10px;
}

p {
  i {
    display: block;
  }
}

.md-field {
  margin-top: 10px;
  margin-bottom: 30px;
}

@media (min-width: $screen-xs) {
  .button-list {
    margin-top: 20px;
  }
}

@media (min-width: $screen-xs2) {
  .login-component {
    .container {
      max-width: 450px;
    }
  }

  .image-container {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-5.jpg");
    background-position: 0% -10%;
  }
}


@media (min-width: $screen-sm) {
  .login-component {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-2.jpg");
    background-position: center bottom;

    .container {
      padding-top: 90px;
    }
  }

  h2 {
    font-size: 2.2rem;
  }

}

@media (min-width: $screen-lg) {
  .login-component {
      background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-2.jpg");
      background-position: center bottom;
  }
}

// .confirm-account {
//   padding: 25px 30px;
//   text-align: center;
// }

// .md-button {
//   margin-top: 40px;
  
//   &.md-center {
//     height: 40px;
//   }
// }

</style>
